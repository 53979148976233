import { useEffect } from 'react'

export default function useExternalStyle(properties = {}, configState){
    useEffect(() => {
        if(configState !== 'available') return

        const head = document.querySelector("head")
        const link = document.createElement("link")

        for(const [key, value] of Object.entries(properties)) link.setAttribute(key, value)
        
        if(link.href && link.id) {
                if(!document.getElementById(link.id)) head.appendChild(link)
        }
        
        return () => {}
    }, [properties, configState])
};
import logoFull from './MesseLogoFull.svg'
import logoSimple from './MesseLogoSimple.svg'
import LanguageSelector from '../LanguageSelector'
import { useTranslation } from 'react-i18next'
import { ContentGroupContext } from '../../providers/ContentGroupProvider'
import { useContext, useEffect, useState } from 'react'
import useExternalScripts from "../../hooks/useExternalScripts"
import useExternalStyle from "../../hooks/useExternalStyle"
import { useConfig } from "../../providers/ConfigProvider"
import './Header.css'

function Header() {
    const { i18n } = useTranslation()
    const { contentGroups } = useContext(ContentGroupContext)
    const [isProfilePage, setIsProfilePage] = useState(false)
    const { config, state: configState } = useConfig()

    useExternalScripts({
      src: config?.accountWidget?.src,
      type: 'module',
      id: 'accountWidgetScript',
      defer: true
    }, configState)
      
    useExternalStyle({
      href: config?.accountWidget?.href,
      rel: 'stylesheet',
      id: 'accountWidgetStyle',
    }, configState)

    const initializeAccountWidget = () => {
      const accountWidget = new window.CDCAccountWidget("account-widget", {
        applicationId: `${contentGroups[3]}`,
        subsidiaryId: `${contentGroups[4]}`,
        language: `${i18n.resolvedLanguage}`,
        cdcApiKey: config?.cdc?.oidcApiKey,
        inline: true,
        loadWebSdkEnabled: true,
        onLogin,
        onLogout
      })
    
      accountWidget.render()
    }
    
    const checkProfilePage = async () => {
        const result = await isOnProfilePage();
        setIsProfilePage(result);
    };

    useEffect(() => {
        checkProfilePage();
    }, []);
    
    useEffect(() => {
        if (isProfilePage) {
            initializeAccountWidget();
            console.log("Initialized account widget");
            return;
        }
        
        if (!isProfilePage) {
            setTimeout(() => {
                checkProfilePage();
            }, 5000)   
        }
    }, [isProfilePage])
    
    const onLogin = () => {
        console.log("Logged in");
    }
    
    const onLogout = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const redirectUrl = searchParams.get('redirectUrl');
    
        if (redirectUrl && redirectUrl === "app://close") {
            // Do nothing
        } else {
            window.location.href = redirectUrl;
        }
    };

    const isOnProfilePage = () => {
        return new Promise((resolve) => {
            setTimeout(() => {
                const element = document.querySelector('.cdc-update-profile-screen');
                resolve(!!element);
            }, 5000);
        });
    };

    return (
        <header className="header">
            <div className="main-header">
                <div className="logo-container">
                    <div className="header-left"></div>
                    <div className="header-center">
                        <img src={logoFull} className="logo-full" alt="Messe Frankfurt" />
                        <img src={logoSimple} className="logo-simple" alt="Messe Frankfurt" />
                    </div>
                    <div className="header-right">
                        <LanguageSelector />
                        <div id="account-widget"></div>
                    </div>
                </div>
            </div>
            <div className="headline">
                <p>Messe-Login</p>
            </div>
        </header>
    )
}

export default Header
